import { Modal } from 'antd';
import React, { useState } from 'react';
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"

const PopupWrapper = styled.div`

`

const Popup = ({ImagePopup}) => {
  const [modal2Open, setModal2Open] = useState(false);

  return (
    <PopupWrapper open={modal2Open}>
      <Modal
        centered
        visible={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <GatsbyImage image={ImagePopup} alt="" />
      </Modal>
    </PopupWrapper>
  );
};

export default Popup;